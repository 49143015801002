<template>
  <div class="container">
    <section class="filtered-barcodes bg-white p-3 rounded shadow-sm">
      <b-row class="align-items-center">
        <b-col cols="6">
          <b-form-group
            label="Filter Berdasarkan"
            label-for="filter-barcodes"
          >
            <b-form-select
                @input="handleInput"
              v-model="selected"
              size="sm"
              :options="options"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Cari Barcodes : "
            label-for="search-for-barcodes"
          >
            <b-form-input
              v-model="table.search"
              type="search"
              placeholder="Search"
              @input="debounceSearch"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </section>
    <section class="view-barcodes bg-white p-3 rounded shadow-sm mt-3">
      <div v-if="isLoading" class="text-center align-items-center">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </div>
      <div
        v-if="barcodes.length === 0 && !isLoading"
        class="text-center"
      >
        Tidak Ada Barcodes.
      </div>
      <div class="barcode-grid">
        <div
          v-for="(barcode, index) in barcodes"
          :key="index"
          class="barcode-item mb-3"
          :class="{ 'barcode_used': barcode.is_used }"
        >
          <svg :id="`barcodeSvg${index}`" />
          <p>Nilai Barcode : {{ barcode.print_value }}</p>
          <p :class="barcode.is_used ? 'text-danger' : 'text-info'">
            Status: {{ barcode.is_used ? 'Sudah Digunakan' : 'Siap Pakai' }}
          </p>
        </div>
      </div>
      <b-pagination
          v-model="table.currentPage"
          class="mb-3"
          align="right"
          :per-page="table.perPage"
          :total-rows="table.total"
          @change="changePage"
      />
    </section>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormSelect, BFormInput, BSpinner, BPagination,
} from 'bootstrap-vue'
import JsBarcode from 'jsbarcode'
import manageBarcodeAPI from '../../../../api/barcode/manageBarcodeAPI'
import tableDataMixins from "../../../../mixins/tableDataMixins";

export default {
  name: 'ListAvailableBarcodes',
  components: {
    BPagination,
    BRow, BCol, BFormGroup, BFormSelect, BFormInput, BSpinner,
  },
  mixins: [tableDataMixins],
  data() {
    return {
      selected: null,
      searchValue: '',
      options: [
        { value: null, text: 'Filter Berdasarkan' },
        { value: '1', text: 'Sudah Digunakan' },
        { value: '0', text: 'Siap Digunakan' },
      ],
      barcodes: [],
      isLoading: false,
    }
  },
  mounted() {
    this.fetchAllBarcodes()
  },
  methods: {
    async fetchAllBarcodes() {
      this.isLoading = true

      const params = {
        is_used: this.selected,
        search: this.table.search,
        page: this.table.currentPage,
        per_page: 50,
      }

      try {
        const response = await manageBarcodeAPI.getAllAvailableBarcodes(params)
        console.log('API Response:', response) // Debug log

        if (Array.isArray(response.data.data.data)) {
          this.barcodes = response.data.data.data
          this.table.total = response.data.data.total
        } else if (response.data && Array.isArray(response.data.data.data)) {
          this.barcodes = response.data.data.data
        } else {
          this.barcodes = []
          console.error('Unexpected API response structure')
        }

        this.debugInfo = `Barcodes: ${JSON.stringify(this.barcodes, null, 2)}`

        this.$nextTick(() => {
          this.generateBarcodes()
        })
      } catch (e) {
        console.error('Error Fetching Barcodes: ', e)
        this.debugInfo = `Error: ${e.message}`
      } finally {
        this.isLoading = false
      }
    },
    generateBarcodes() {
      this.barcodes.forEach((barcode, index) => {
        if (barcode && barcode.print_value) {
          JsBarcode(`#barcodeSvg${index}`, barcode.print_value, {
            format: 'CODE128',
            width: 2,
            height: 100,
            displayValue: true,
          })
        } else {
          console.error(`Invalid barcode data at index ${index}:`, barcode)
        }
      })
    },
    handleInput(selectedValue) {
      console.log('Selected Value:', selectedValue)

      this.selected = selectedValue

      this.fetchAllBarcodes()
    },
    debounceSearch() {
      clearTimeout(this.table.debounceTimer);

      this.table.debounceSearch = setTimeout(() => {
        this.onChangeSearchTable();
      }, 500);
    },
    onChangeSearchTable() {
      this.fetchAllBarcodes();
    },
    changePage(page) {
      this.table.currentPage = page;
      this.fetchAllBarcodes()
    }
  },
}
</script>

<style scoped>
.barcode_used {
  background-color: #ff8b9a !important;
}

.barcode-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.barcode-item {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.barcode-item svg {
  max-width: 100%;
  height: auto;
}

.barcode-card {
  height: 400px;
}

.barcode-container {
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
}

.barcode-item {
  margin-bottom: 10px;
}

</style>
